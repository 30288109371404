<template>

    <div class="w-full h-full px-3 py-4 relative">

        <div class="h-full w-full flex flex-col">

            <div class="h-full overflow-auto no-scrollbar">

                <All model="Syncs" :immediate="false" v-slot="{data,loading}" :data.sync="syncs" ref="callsync">

                    <div v-if="!loading" class="h-full flex flex-col overflow-auto no-scrollbar">

                        <div v-if="data[0].sync_status.name == 'Finalizado'" class="h-14 flex-none bg-good_transparent mb-2 flex flex-col justify-center items-center">

                            <span class="text-sm font-semibold text-green">{{ $t('oksyncro') }}</span>
                            <span class="text-sm font-semibold text-green">{{data[0].SyncData}}</span>

                        </div>

                        <div v-if="data[0].sync_status.name == 'Error'" class="h-12 flex-none bg-bad_transparent mb-2 flex flex-col justify-center items-center">

                            <span class="text-sm font-semibold text-bad">{{ $t('errorsyncro') }}</span>
                            <span class="text-sm font-semibold text-bad">{{data[0].SyncData}}</span>

                        </div>

                        <div v-if="data[0].sync_status.name == 'Ejecutando'" class="h-12 flex-none bg-blue_transparent mb-2 flex flex-col justify-center items-center">

                            <span class="text-sm font-semibold text-blue">{{ $t('doingsyncro') }}</span>
                            <span class="text-sm font-semibold text-blue">{{data[0].SyncData}}</span>

                        </div>

                        <div class="h-20 flex-none flex flex-row justify-between items-center relative">

                            <div v-if="data[0].sync_status.name == 'Ejecutando'" class="absolute w-full h-40 bg-darkgray_transparent"></div>

                            <div class="h-full w-1/2 pr-1 py-1 flex flex-col justify-center items-center">
                            
                                <div class="h-full w-full rounded-lg shadow flex flex-col justify-center items-center p-1" :class="{'bg-red':data[0].sync_status.name != 'Ejecutando', 'bg-gray':data[0].sync_status.name == 'Ejecutando'}" @click="doSync(true)">

                                    <span class="text-sm text-white font-semibold text-center">{{ $t('completesyncro') }}</span>
                                
                                </div>
                            
                            </div>

                            <div class="h-full w-1/2 pl-1 py-1 flex flex-col justify-center items-center">
                            
                                <div class="h-full w-full rounded-lg shadow flex flex-col justify-center items-center p-1" :class="{'bg-red':data[0].sync_status.name != 'Ejecutando', 'bg-gray':data[0].sync_status.name == 'Ejecutando'}" @click="doSync(false)">

                                    <span class="text-sm text-white font-semibold text-center">{{ $t('nosalessyncro') }}</span>
                                
                                </div>
                            
                            </div>

                        </div>

                        <div class="h-12 w-full flex flex-row mt-4">

                            <div class="h-full w-full py-1 px-2 flex flex-row justify-between items-center">

                                <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('syncs')}}</span>

                            </div>

                        </div>

                        <div v-for="(el,index) in data" :key="index" class="h-16 flex-none mb-2 rounded-lg flex flex-row overflow-hidden" :class="'bg-box-'+mode">

                            <div class="h-full w-20 flex flex-col justify-center items-center">

                                <div v-if="el.sync_status.name == 'Finalizado'" class="h-10 w-10 rounded-full flex flex-col justify-center items-center bg-good">
                                    <i class="mdi mdi-check text-white font-semibold text-xl"></i>
                                </div>

                                <div v-if="el.sync_status.name == 'Error' || (index > 0 && el.sync_status.name == 'Ejecutando')" class="h-10 w-10 rounded-full flex flex-col justify-center items-center bg-bad">
                                    <i class="mdi mdi-close text-white font-semibold text-xl"></i>
                                </div>

                                <div v-if="el.sync_status.name == 'Ejecutando' && index == 0" class="h-10 w-10 rounded-full flex flex-col justify-center items-center bg-blue">
                                    <i class="mdi mdi-autorenew text-white font-semibold text-xl"></i>
                                </div>

                                <div v-if="el.sync_status.name == 'Inactivo'" class="h-10 w-10 rounded-full flex flex-col justify-center items-center bg-gray">
                                    <i class="mdi mdi-progress-clock text-white font-semibold text-xl"></i>
                                </div>

                            </div>

                            <div class="h-full flex-1 flex flex-row justify-start items-center">

                                <span class="text-md font-semibold" :class="'text-dfont-'+mode">{{el.SyncData}}</span>
                                <!-- {{el.sync_status.name}} -->

                            </div>

                        </div>
                    
                    </div>

                    <div v-else class="h-20 relative">
                        <loader :loading="loading"></loader>
                    </div>

                </All>

            </div>

        </div>

    </div>
    
</template>

<script>
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import loader from '../../components/loader.vue';
import DoSync from '../../api/models/DoSync';

export default {
    components:{
        All,
        Request,
        loader,
        DoSync
    },
    data(){
        return{
            syncs: [],
            loading: false
        }
    },
    methods:{
        async doSync(ifcomplete){


            if(this.syncs[0].sync_status.name != 'Ejecutando'){

                // ejecutar la llamada
                try {
                    this.loading = true;
                    this.$refs['callsync'].request();
                    await DoSync.all({ Complete: ifcomplete });
                } catch(error) {
                } finally {
                    this.loading = false;
                }

            } else {

            }

        }
    },
    computed:{
        mode(){
            return state.mode
        }
    },
    mounted(){
        this.$refs['callsync'].request();

        setInterval(() => {
            this.$refs['callsync'].request();
        }, 30000);
    }
}
</script>